<template>
    <div id="expertList" style="background-color: #F0F0F0;">
        <div style="height: auto;width:1500px;margin: 110px auto 0;background-color: #ffffff;">
            <div class="navigation">
                <div class="back" @click="back"><i class="el-icon-arrow-left" style="margin-right: 8px;"/>返回</div>
                <div class="title">咨询订单</div>
            </div>
            <div class="mainPlate">
                <div class="plate">
                    <div class="screen">
                        <el-form :inline="true" :model="formInline" class="demo-form-inline">
                            <el-form-item>
                                <el-date-picker v-model="formInline.date" type="date" placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-select @change="checkoutStatus" v-model="formInline.status" placeholder="选择订单状态">
                                    <el-option v-for="(item, index) in statusOption" :key="index" :label="item.name" :value="item.status"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="formInline.expert" placeholder="选择预约专家">
                                    <el-option label="区域一" value="shanghai"></el-option>
                                    <el-option label="区域二" value="beijing"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button>重置</el-button>
                                <el-button type="primary" style="background-color: #2970FF;">搜素</el-button>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="formInline.like" placeholder="输入关键词搜索"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="orderlist">
                        <el-table :data="newOrder" style="width: 100%;margin-bottom: 20px"
                                  :row-class-name="tableRowClassName"
                                  :header-cell-class-name="headerCellClassName4">
                            <el-table-column prop="topicTitle" label="话题" width="280" align="center">
                            </el-table-column>
                            <el-table-column prop="title" label="咨询标题" width="280" align="center">
                            </el-table-column>
                            <el-table-column prop="expertName" label="专家" width="200" align="center">
                            </el-table-column>
                            <el-table-column prop="creatTime" label="下单时间" width="180" align="center">
                            </el-table-column>
                            <el-table-column prop="creatTime" label="预约时间" width="180" align="center">
                            </el-table-column>
                            <el-table-column prop="status" label="订单状态" width="160" align="center">
                                <template slot-scope="scope">
                                    <div style="font-size: 16px;" :style="{'color': orderColor(scope.row.status)}">{{orderStatus(scope.row.status)}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="160" align="center">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick(scope.row.orderNo)" type="text" size="small">
                                        查看详情
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "",
        components: {
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login() {
                return this.$store.state.is_login
            }
        },
        data() {
            return {
                page: 1,
                pageSize: 10,
                more: 1,
                formInline: {
                    status: 0,
                    expert: '',
                    date: "",
                    like: ""
                },
                statusOption: [
                    {status: 0, name: '全部'},
                    {status: 1, name: '待接单'},
                    {status: 2, name: '待付款'},
                    {status: 3, name: '进行中'},
                    {status: 4, name: '待确认'},
                    {status: 5, name: '待评价'},
                    {status: 6, name: '已完成'},
                ],
                newOrder: [],
            }
        },
        props: {},
        created: function () {

        },
        mounted: function () {
            let that = this;
            this.getMyExpretOrderList(0);
            window.onscroll = function(){
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //变量windowHeight是可视区的高度
                let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                //滚动条到底部的条件
                let ratio = 0;
                let screen = window.screen;
                let ua = navigator.userAgent.toLowerCase();
                if (typeof window.devicePixelRatio !== "undefined") {
                    ratio = window.devicePixelRatio;
                } else if (~ua.indexOf('msie')) {
                    if (screen.deviceXDPI && screen.logicalXDPI) { //IE
                        ratio = screen.deviceXDPI / screen.logicalXDPI;
                    }
                } else if (typeof window.outerWidth !== 'undefined' && typeof window.innerWidth !== 'undefined') {
                    ratio = window.outerWidth / window.innerWidth;
                }

                if (ratio <= 1) {
                    if (scrollTop + windowHeight >= scrollHeight) {
                        if (that.more == 1) {
                            that.getMyExpretOrderList(that.formInline.status)
                        } else {
                            that.utils.sus("已加载完");
                        }
                    }
                } else {
                    if (scrollHeight - scrollTop - windowHeight < 10) {
                        if (that.more == 1) {
                            that.getMyExpretOrderList(that.formInline.status)
                        } else {
                            that.utils.sus("已加载完");
                        }
                    }
                }
            }
        },
        methods: {
            // 切换状态
            checkoutStatus(status) {
                this.newOrder = [];
                this.page = 1;
                this.more = 1;
                this.getMyExpretOrderList(status)
            },

            back() {
                this.$router.back();
            },
            handleClick(orderNo) {
                this.utils.a('/expert/order/' + orderNo);
            },

            getMyExpretOrderList(status) {
                var that = this;
                this.newApi.getMyExpretOrderList({
                    status: status,
                    page: that.page,
                    pageSize: that.pageSize
                }).then(res => {
                    for (const item of res.data) {
                        that.newOrder.push(item)
                    }
                    if (res.data < that.pageSize) {
                        that.more = 0
                    }
                    that.page ++
                })
            },

            tableRowClassName() {
                return 'success-row';
            },
            headerCellClassName4({columnIndex}) {
                if (columnIndex === 0) {
                    return 'headerFirstCell';
                } else if (columnIndex === 7) {
                    return 'headerLastCell';
                }
                return 'headerCell'
            },
            orderStatus(status) {
                if(status == 0){
                    return "已拒绝"
                } else if (status == 1) {
                    return "待接单"
                } else if (status == 2) {
                    return "待支付"
                } else if (status == 3) {
                    return "进行中"
                } else if (status == 4) {
                    return "待确认"
                } else if (status == 5) {
                    return "待评价"
                } else if (status == 6) {
                    return "已完成"
                } else if (status == 7) {
                  return "退款申请中"
                }else if (status == 8) {
                    return "退款完成"
                }
            },
            orderColor(status) {
                if (status == 1) {
                    return "#F44336"
                } else if (status == 2) {
                    return "#F44336"
                } else if (status == 3) {
                    return "#FF9800"
                } else if (status == 4) {
                    return "#F44336"
                } else if (status == 5) {
                    return "#2970FF"
                } else if (status == 6) {
                    return "#8BC34A"
                }else if (status == 7) {
                    return "#F74108"
                }else if (status == 8) {
                    return "#1D82FE"
                }
            }
        }
    }

</script>

<style>
    #expertList .navigation {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #444444;
        text-align: center;
        height: 44px;
        border-bottom: 1px solid #CCCCCC;
    }

    #expertList .navigation .back {
        font-size: 18px;
        width: 100px;
        font-weight: 600;
        cursor: pointer;
    }

    #expertList .navigation .title {
        font-size: 20px;
        font-weight: 600;
        flex: 1;
    }

    #expertList .mainPlate {
        display: flex;
        justify-content: flex-start;
        min-height: calc(100vh - 155px);
    }

    #expertList .mainPlate .menu {
        width: 180px;
        flex: 0 0 auto;
        background-color: #F5F5F5;
    }

    #expertList .mainPlate .menu .menu_item {
        height: 76px;
        line-height: 76px;
        text-align: center;
        font-size: 20px;
        color: #444444;
        cursor: pointer;
    }

    #expertList .mainPlate .menu .menu_item.active {
        color: #FF9800;
        background-color: #ffffff;
    }

    #expertList .mainPlate .plate {
        flex: 1;
        padding: 0 30PX;
    }

    #expertList .mainPlate .plate .screen {
        height: 70px;
        padding-top: 22px;
    }

    #expertList .card-content {
        width: 240px;
        height: 104px;
        background-color: #F3F3F3;
        padding: 15px 18px;
        border-radius: 5px;
        margin-right: 16px;
        margin-bottom: 16px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    #expertList .mainPlate .plate .screen .el-form--inline .el-form-item {
        margin-right: 20px;
    }

    #expertList .mainPlate .plate .orderstatus {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 16px;
        font-size: 20px;
    }

    #expertList .mainPlate .plate .orderstatus .circular {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 10px;
    }

    #expertList .mainPlate .plate .el-table .headerCell {
        height: 40px;
        color: #ffffff;
        text-align: center;
        background-color: #2970FF;
    }

    #expertList .mainPlate .plate .el-table .headerFirstCell {
        border-top-left-radius: 5px;
        height: 40px;
        color: #ffffff;
        background-color: #2970FF;
        text-align: center;
    }

    #expertList .mainPlate .plate .el-table .headerLastCell {
        border-top-right-radius: 5px;
        height: 40px;
        color: #ffffff;
        background-color: #2970FF;
        text-align: center;
    }

    #expertList .mainPlate .plate .el-table .success-row {
        height: 56px;
        background: #F5F5F5;
        text-align: center;
    }

    .red {
        background-color: #F44336 !important;
    }

    .yellow {
        background-color: #FF9800 !important;
    }

    .green {
        background-color: #8BC34A !important;
    }
</style>
